import React, {
    useEffect,
    useState
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { loginMe, logout } from '../../actions/auth';
import authService from '../../services/auth.service';
import TokenService from '../../services/token.service';

function Auth({ children }) {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        const initAuth = async () => {

            //TokenService.handleAuthentication();

            //if (TokenService.isAuthenticated()) {
            try {
                const user = await authService.me();
                if (user.accessToken) {
                    await dispatch(loginMe(user));
                }
                //}

                setLoading(false);
            } catch (error) {
                setLoading(false);
            }

        };

        initAuth();
    }, [dispatch]);

    if (isLoading) {
        return <div className='fixed z-50 left-0 top-0 bottom-0 right-0 flex justify-center items-center bg-white bg-opacity-25'>

            <img className='w-56 h-56 animate-pulse' src='https://d18i3wxus81zw3.cloudfront.net/fit-in/512x512/valle-norte.png' />

        </div>;
    }

    return children;
}

Auth.propTypes = {
    children: PropTypes.any
};

export default Auth;
