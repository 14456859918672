import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
//import { createBrowserHistory } from 'history';
import ScrollReset from './components/ScrollReset';
import Routes from './Routes';
import Auth from './components/Auth';

//const history = createBrowserHistory();

const App = () => {

  /*
  useEffect(() => {
    window.addEventListener('keypress', e => {
      console.log(e.key)
    });
  }, []);
  */
  return (
    <BrowserRouter>
      <Auth>
        <ScrollReset />
        <Routes />
      </Auth>
    </BrowserRouter >
  );
}

export default App;
